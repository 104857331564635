function FloatSocial() {
  return (
    <div className="float-sm">
      <div className="fl-fl float-ln">
        <i className="fab fa-2x fa-linkedin white-color"></i>
        <a href="https://www.linkedin.com/company/sharepass/" target="blank">
          On Linkedin!
        </a>
      </div>
      <div className="fl-fl float-fb">
        <i className="fab fa-2x fa-facebook white-color"></i>
        <a href="https://www.facebook.com/sharepasscom" target="blank">
          On Facebook!
        </a>
      </div>
      <div className="fl-fl float-tw">
        <i className="fab fa-2x fa-twitter white-color"></i>
        <a href="https://twitter.com/SharePassOnline" target="_blank" rel="noreferrer">
          On Twitter!
        </a>
      </div>
      <div className="fl-fl float-ga">
        <i className="fab fa-2x fa-reddit white-color"></i>
        <a href="https://www.reddit.com/r/SharePass/" target="blank">
          On Reddit!
        </a>
      </div>
      <div className="fl-fl float-yt">
        <i className="fab fa-2x fa-youtube white-color"></i>
        <a href="https://www.youtube.com/channel/UCKNd8SHrdIObaxt8xO7Mg2w" target="blank">
          On YouTube!
        </a>
      </div>
    </div>
  );
}

export default FloatSocial;
