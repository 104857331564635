import { Helmet } from "react-helmet";
function SubscriptionTerms() {
  return (
    <section className="page-container">
      <Helmet>
        <title>Subscription terms | SharePass</title>
        <meta
          property="og:title"
          content="SharePass | Subscription terms | Don't risk it, SharePass it!"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <h1 className="text-center section-heading">
              {" "}
              Subscription terms{" "}
            </h1>

            <p>
              <strong>Offer Terms</strong>
            </p>

            <p>
              All SharePass paid services are subscribed on a service period
              basis. The length of your first subscription term depends on your
              purchase selection. Please note that your chosen offer term may
              change after the initial subscription term (provided in the offer
              or notified to you separately).
            </p>

            <p>
              If the offer includes a discount off the total price a customer
              typically pays for the initial subscription of the selected term,
              the discount is subject to changes at the end of the initial
              subscription term.
            </p>

            <p>
              By submitting your payment details, you (i) confirm your
              acceptance of the offer advertised; (ii) accept and agree to these{" "}
              <strong>
                <a href="/subscription-terms">Subscription Terms</a>
              </strong>{" "}
              and hereby; and (iii) acknowledge and agree to our 
              <strong>
                <a href="/terms-of-service">Terms of Service</a>
              </strong>{" "}
              and{" "}
              <strong>
                <a href="/permitted-use-policy">Permitted Use Policy</a>
              </strong>{" "}
              and{" "}
              <strong>
                <a href="/privacy-policy">Privacy Policy</a>
              </strong>{" "}
              and{" "}
              <strong>
                <a href="/cookies-policy">Cookies Policy</a>
              </strong>
              .
            </p>

            <p>
              <strong>Subscription and Automatic Renewal Terms</strong>
            </p>

            <p>
              After the end of your initial plan, your subscription will be
              automatically renewed, and you will be charged the then-current
              price of the Service. For more information, read our 
              <strong>
                <a href="/terms-of-service">Terms of Services</a>
              </strong>
              .
            </p>

            <p>
              For monthly plans: your chosen payment method will be charged at
              least 7 days before the next period begins unless you decide to
              cancel your automatic payments for the services before the new
              charge is made.
            </p>

            <p>
              For other plans: your chosen payment method will be charged at
              least 14 days before the next period begins unless you decide to
              cancel your automatic payments for the services before the new
              charge is made.
            </p>

            <p>
              <strong>Price Terms</strong>
            </p>

            <p>
              All prices, including recurring subscription fees, are subject to
              change. Any changes in pricing will be provided in the initial
              offer or communicated to you in advance.
            </p>

            <p>
              <strong>Refund & Cancellations</strong>
            </p>

            <p>
              If you wish to claim a refund, you can do so within 30 days
              following your purchase of our services.
            </p>

            <p>
              You can cancel a recurring subscription at any time from your{" "}
              <strong>
                <a href="https://app.sharepass.com/admin/settings/plans">
                  SharePass online account
                </a>
              </strong>
              . Cancelled accounts will not be refunded for the unused part of
              the ongoing service period. For further information about the
              Refund and Cancellation Policy, read our 
              <strong>
                <a href="/terms-of-service">Terms of Services</a>
              </strong>
              .
            </p>

            <p>
              <strong>Taxes and Withholding</strong>
            </p>

            <p>
              You are responsible for all applicable sales, services,
              value-added, goods and services tax (GST), withholding, tariffs,
              Universal Services Fund (USF) fees or any other similar fees as
              may be applicable in the location in which the Services are being
              provided and similar taxes or fees (collectively,{" "}
              <strong>Taxes and Fees</strong>) imposed by any government entity
              or collecting agency based on the Services, except those Taxes and
              Fees based on our net income, or Taxes and Fees for which you have
              provided an exemption certificate.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SubscriptionTerms;
