import { Helmet } from "react-helmet";

function ErrorPage() {
    return (
        <header className="masthead ymt-masthead bg-mountains">
            <Helmet>
                <title>Error | SharePass</title>
                <meta property="og:title" content="SharePass | Error | Don't risk it, SharePass it!" />
            </Helmet>
            <div className="container h-100">

                <div className="row h-100">

                    <div id="response-outer-container" className="col-lg-12">
                        <div className="row mb-2">
                            <div className="col-lg-2"></div>
                            <div id="response-inner-container" className="col-lg-8 class-error-wrapper">
                                <div className='text-center'>
                                    <div><i className='fas fa-10x fa-exclamation-triangle mb-5'></i></div>
                                    <p>Whoops! there was a problem finding your page.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </header>
    );
}

export default ErrorPage;