function Header() {
    return (
    <header className="masthead ymt-masthead">
        <video preload="yes" id="generate-video" poster="/assets/img/start.jpg" playsInline={true} muted={true}>
            <source src="/assets/videos/animation.mp4" type="video/mp4" />
            <source src="/assets/videos/animation.webm" type="video/webm" />
            Your browser does not support the video tag.
        </video>
        <audio id="error-audio" style={{display: "none"}}>
            <source src="/assets/audios/error.wav" type="audio/wav" />
            Your browser does not support the audio tag.
        </audio>

        <audio id="lock-audio" style={{display: "none"}}>
            <source src="/assets/audios/lock.wav" type="audio/wav" />
            Your browser does not support the audio tag.
        </audio>
        <div className="container h-100">

            <div className="row h-100">
                <div className="col-lg-7 col-md-12 col-sm-12 free-form" style={{display: "none"}}>
                    <div className="main-intro">DON'T RISK IT, <b>SHAREPASS IT!</b></div>

                    <div id="generateLinkForm">
                        <div className="row">
                            <div className="menu-secret-options">
                                <div className="btn btn-primary" id="btnSecretOptions">
                                    <i className="fas fa-ellipsis-v"></i>
                                </div>
                            </div>

                            <div className="secret-options" style={{display: "none"}}>

                                <div className="form-group row">

                                    <label htmlFor="secretType" className="col-5 col-form-label secret-options-tooltip"
                                        title="The secret can be a password or a message (messages allow up to 1000 characters)."
                                        data-toggle="tooltip"><i className="fas fa-key"></i>
                                        &nbsp;Secret</label>

                                    <div className="col-7 pl-0">
                                        <div className="radio-button">
                                            <input type="radio" name="secretType" data-field="type"
                                                id="secretTypepassword" defaultValue="password" />
                                            <label htmlFor="secretTypepassword">Password</label>
                                        </div>
                                        <div className="radio-button" style={{marginLeft: "3px"}}>
                                            <input type="radio" name="secretType" data-field="type"
                                                id="secretTypemessage" defaultValue="message" />
                                            <label htmlFor="secretTypemessage">Message</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="ttl" className="col-5 col-form-label secret-options-tooltip"
                                        title="With the time to live you can set for how long the secret will be available."
                                        data-toggle="tooltip"><i className="far fa-clock"></i>
                                        &nbsp;Time to live</label>
                                    <div className="col-7 pl-0">
                                        <div className="row">
                                            <div id="ttlWrapper">
                                                <input type="text" defaultValue="" data-field="ttl" id="ttl" min="1"
                                                    className="form-control" />
                                            </div>

                                            <div id="ttlTypeWrapper">
                                                <select data-type="ttl" data-field="ttlType" id="ttlType"
                                                    className="form-control available-type">
                                                    <option value="60">Minutes</option>
                                                    <option value="3600">Hours</option>
                                                    <option value="86400">Days</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group mb-0 row">
                                    <label htmlFor="otl" className="col-5 col-form-label secret-options-tooltip"
                                        title="If this option is activated the secret will be destroyed once retrieved."
                                        data-toggle="tooltip"><i className="fas fa-redo-alt"></i>
                                        &nbsp;One-time link</label>
                                    <div className="col-7 pl-0">
                                        <label className="container-checkbox">
                                            <input type="checkbox" id="otl" data-field="otl" className="styled-checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                        <span id="otlWarning" className="text-danger danger-tooltip" style={{display: "none"}}
                                            title="The link will be available to any person who has access to it while the link is not expired. Use limit: 5 times."
                                            data-toggle="tooltip"><i className='fas fa-exclamation-triangle'></i>
                                            Warning</span>
                                    </div>
                                </div>

                            </div>

                            <div className="secret-input">

                                <div id="messageInputWrapper" className="form-group group-message for-message"
                                    style={{display: "none"}}>
                                    <div className="input-group">

                                        <textarea className="form-control text-expandable" id="message"
                                            placeholder="Message to SharePass" maxLength="1000"></textarea>

                                    </div>
                                </div>

                                <div id="passwordInputWrapper" style={{display: "none"}}
                                    className="form-group group-password for-password">
                                    <div className="input-group">

                                        <input type="password" className="form-control" id="secret"
                                            placeholder="Password to SharePass" maxLength="30"
                                            aria-label="Password to share" />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-primary lock-prepend" type="button"
                                                id="copySecret"><i className="fas fa-eye"></i></button>
                                        </div>

                                    </div>
                                </div>

                                <div className="form-group group-indicator for-password" style={{display: "none"}}>
                                    <div className="indicator">
                                        <span className="weak"></span>
                                        <span className="medium"></span>
                                        <span className="strong"></span>
                                    </div>
                                    <div id="indicator-text"></div>
                                </div>

                                <div className="form-group text-center">
                                    <button id="generateLinkBtn" className="btn btn-primary"><i
                                            className="far fa-paper-plane"></i> SharePass it!</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div id="linkGeneratedWrapper"></div>
                    <div id="errorWrapper"></div>
                    <div id="successWrapper"></div>
                    <div id="sharepass-progressbar-timer"></div>
                </div>

                <div id="response-outer-container" className="col-lg-12" style={{display: "none"}}>
                    <div className="row mb-2">
                        <div className="col-lg-2"></div>
                        <div id="response-inner-container" className="col-lg-8"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <button id="btnShowForm" className="btn btn-primary"><i className="fas fa-key"></i> SharePass new
                                secret</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="arrows-container">
            <div>
                <small>more info</small>
            </div>
            <svg className="arrows">
                <path className="a1" d="M0 0 L20 12 L40 0"></path>
                <path className="a2" d="M0 20 L20 32 L40 20"></path>
                <path className="a3" d="M0 40 L20 52 L40 40"></path>
            </svg>
        </div>
    </header>
    );
}

export default Header;