import { Helmet } from "react-helmet";
//import { Controller, Scene } from 'react-scrollmagic';
//import { Timeline, Tween } from 'react-gsap';

function Home() {
  const isMobileTablet = function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  if (true) {
    return (
      <div>
        <Helmet>
          <meta
            property="og:title"
            content="SharePass | Don't risk it, SharePass it!"
          />
        </Helmet>

        <div id="sharepass-explainer"></div>
        <section id="explainer" className="explainer">
          <div className="container">
            <div className="row">
              <div className="col-md-12 module-fadein" data-delay="400">
                <div className="section-heading text-center mb-3">
                  <h2>Explainer</h2>
                </div>

                <iframe
                  className="video-explainer"
                  src="https://www.youtube.com/embed/F1WKad67hXk?rel=0"
                  frameBorder="0"
                  title="Explainer"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen={true}
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <section className="text-center" id="sharepass">
          <div id="sharepassSlider">
            <div className="slider-panel certifications-home">
              <div className="slider-panel-inner-wrapper">
                <div className="slider-panel-inner">
                  <div className="row">
                    <div className="col-lg-12 my-auto">
                      <div className="section-heading text-center module-fadein">
                        <h2>
                          <i className="fas fa-shield-alt"></i> WE CARE ABOUT
                          SECURITY
                        </h2>
                        <h4 className="text-muted">&nbsp;</h4>
                        <div className="feature-desc">
                          <p className="white-color">
                            SharePass complies with the latest cybersecurity
                            industry standards protecting your data against
                            cyber threats.
                          </p>
                          <p className="white-color">
                            SharePass goes through regular assessments such as
                            penetration testing, cloud audits, and code reviews
                            to maintain its high level of security now and into
                            the future.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 my-auto">
                      <div className="container-fluid">
                        <div className="row will-fadeIn">
                          <div
                            id="silentbreach-badge"
                            customerid="sharepass-8bbe2cd36ee63289b9e9f8d6b4ca4b4c"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-panel statistics-home">
              <div className="slider-panel-inner-wrapper">
                <div className="slider-panel-inner">
                  <div className="col-md-12 d-none" data-delay="200">
                    <div className="section-heading">
                      <h2 className="section-heading">
                        <i className="fas fa-chart-pie"></i>&nbsp;Statistics
                      </h2>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                  <div className="col-md-12 module-fadein" data-delay="400">
                    <div className="row">
                      <div className="col-md-4 col-6">
                        <div className="counter mx-auto">
                          <div className="row">
                            <div className="col-md-12 mb-3 stats-icons-container">
                              <i className="stats-icons fas fa-leaf fa-6x green-sust"></i>
                            </div>
                            <div className="col-md-12">
                              <h2
                                id="operationsTime"
                                className=" count-title count-number"
                              ></h2>
                              <p className="count-text sharepass-font">
                                ENERGY EFFICIENCY
                              </p>
                              <p className="green-sust d-none-m768">
                                SharePass reduces energy consumption by running
                                its core services on demand
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 col-6">
                        <div className="counter mx-auto">
                          <div className="row">
                            <div className="col-md-12 mb-3 stats-icons-container">
                              <i className="stats-icons fas fa-unlock-alt fa-6x"></i>
                            </div>
                            <div className="col-md-12">
                              <h2
                                id="secretsSharePassed"
                                className="timer count-title count-number"
                                data-to="100"
                                data-speed="1500"
                              ></h2>
                              <p className="count-text sharepass-font">
                                SECRETS SHAREPASSED
                              </p>
                              <p className="white-color d-none-m768">
                                Total secrets generated and delivered using
                                SharePass
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 mmt-3">
                        <div className="row">
                          <div className="col-3"></div>
                          <div className="col-md-12 col-6">
                            <div className="counter mx-auto">
                              <div className="row">
                                <div className="col-md-12 mb-3 stats-icons-container">
                                  <i className="stats-icons fas fa-database fa-6x"></i>
                                </div>
                                <div className="col-md-12">
                                  <h2 id="dataSharePassed" className=""></h2>
                                  <p className="count-text sharepass-font">
                                    DATA SHAREPASSED
                                  </p>
                                  <p className="white-color d-none-m768">
                                    Total data transferred using SharePass (On
                                    average, a 10 characters secret is 270
                                    bytes)
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-panel mobileapp-home">
              <div className="slider-panel-inner-wrapper">
                <div className="slider-panel-inner">
                  <div className="col-md-12" data-delay="200">
                    <div className="section-heading">
                      <h2 className="section-heading">
                        Mobile App
                      </h2>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                  <div className="col-md-12 module-fadein" data-delay="400">
                    <h2
                      className=""
                    ></h2>
                    <p className="count-text sharepass-font">
                      Available for iPhone in the App Store
                    </p>
                    <div className="text-center">
                      <a href="https://apps.apple.com/app/sharepass/id1627340541" target="_blank" rel="noopener">
                        <img src="/assets/img/download-apple.svg" alt="Download on the App Store" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-panel statistics-home">
              <div className="slider-panel-inner-wrapper">
                <div className="slider-panel-inner">
                  <div className="col-md-12" data-delay="200">
                    <div className="section-heading">
                      <h2 className="section-heading">
                        <i className="fas fa-map-marked-alt"></i>&nbsp;Our
                        Presence
                      </h2>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                  <div className="col-md-12 module-fadein" data-delay="400">
                    <div className="row">
                      <div className="col-md-12 d-none d-md-none d-lg-block d-xl-block">
                        <img
                          src="/assets/img/map-big-screen.png"
                          style={{ maxHeight: "60vh", maxWidth: "100%" }}
                        />
                      </div>
                      <div className="col-md-12 d-none d-sm-block d-md-block d-lg-none d-xl-none">
                        <img
                          src="/assets/img/map-small-screen.png"
                          style={{ maxWidth: "100%" }}
                        />
                      </div>
                      <div className="col-md-12 d-block d-sm-none d-md-none d-lg-none d-xl-none">
                        <div className="row mb-5">
                          <div className="col-6">
                            <div className="mb-2">
                              <img
                                src="/assets/img/usa.png"
                                style={{ maxWidth: "50%" }}
                              />
                            </div>
                            <h3>United States</h3>
                          </div>
                          <div className="col-6">
                            <div className="mb-2">
                              <img
                                src="/assets/img/australia.png"
                                style={{ maxWidth: "50%" }}
                              />
                            </div>
                            <h3>Australia</h3>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-12">
                            <h2>Soon</h2>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="mb-2">
                              <img
                                src="/assets/img/singapore.png"
                                style={{ maxWidth: "25%" }}
                              />
                            </div>
                            <h3>Singapore</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-panel about-business bg-none">
              <div id="sharepass-about"></div>
              <div className="slider-panel-inner-wrapper">
                <div className="slider-panel-inner">
                  <div className="row">
                    <div className="col-md-1"></div>

                    <div className="col-md-10">
                      <div className="text-box-style">
                        <h2 className="section-heading">About the business</h2>
                        <p className="white-color">
                          SharePass is an online digital security solution for
                          confidential information sharing.
                        </p>
                        <p className="white-color">
                          SharePass was established in 2020 by 3 security
                          engineers.
                        </p>
                        <p className="white-color">
                          "We recognised a real need to communicate securely,
                          easily!".
                        </p>
                        <p className="white-color">
                          For{" "}
                          <b style={{ fontWeight: "bolder" }}>individuals</b>,
                          it allows transferring confidential data safely
                          without leaving a{" "}
                          <a
                            onClick={() => {
                              return false;
                            }}
                            className="footprint-tooltip"
                            title="Digital footprint refers to the information about a particular person that exists on the internet as a result of their online activity."
                            data-toggle="tooltip"
                          >
                            digital footprint
                          </a>
                          .
                        </p>
                        <p className="white-color">
                          For <b style={{ fontWeight: "bolder" }}>business</b>,
                          it allows the same plus secure communication,
                          credentials protection and overall improvement of the
                          security profile.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-panel why-sharepass">
              <div className="slider-panel-inner-wrapper">
                <div className="slider-panel-inner">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="section-heading">
                        <i className="far fa-question-circle"></i>&nbsp;Why you
                        should use SharePass
                      </h2>

                      <p className="white-color">
                        When you share confidential information over traditional
                        channels such as notes, SMS, chatting tools, or emails,
                        you leave a{" "}
                        <a
                          onClick={() => {
                            return false;
                          }}
                          className="footprint-tooltip"
                          title="Digital footprint refers to the information about a particular person that exists on the internet as a result of their online activity."
                          data-toggle="tooltip"
                        >
                          digital footprint
                        </a>
                        . Unknowingly, this footprint remains available in clear
                        text, allowing it to be discovered by hackers and other
                        users.
                      </p>

                      <p className="white-color">
                        When using SharePass, you can securely share the
                        required information by encrypting it and sending the
                        link to the recipient. Once the recipient has opened the
                        link, it becomes inactive, leaving no traces of your
                        confidential data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-panel how-to bg-none">
              <div className="slider-panel-inner-wrapper">
                <div className="slider-panel-inner">
                  <div className="row">
                    <div className="col-md-12" data-delay="200">
                      <div className="row mt-2 mb-2">
                        <div className="col-md-12">
                          <div className="section-heading">
                            <h2 className="section-heading">
                              <i className="far fa-question-circle"></i>
                              &nbsp;How to use SharePass
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <ul id="how-to-list">
                            <li>
                              <p>
                                <i className="fas fa-comment-dots"></i>&nbsp;
                                Type your secret message
                              </p>
                            </li>
                            <li>
                              <p>
                                <i className="fas fa-key"></i>&nbsp; Click{" "}
                                <b>SharePass it!</b> button
                              </p>
                            </li>
                            <li>
                              <p>
                                <i className="far fa-copy"></i>&nbsp; Copy the
                                link generated
                              </p>
                            </li>
                            <li>
                              <p className="mb-0">
                                <i className="fas fa-share-alt-square"></i>
                                &nbsp; Share the link
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-lg-12">
                              <video
                                id="demo-video"
                                width="100%"
                                autoPlay={true}
                                muted={true}
                                loop={true}
                                playsInline={true}
                              >
                                <source
                                  src="/assets/videos/SP_demo.mp4"
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                              {/*<iframe
                                frameBorder="0"
                                allowFullScreen="1"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                title="How to use SharePass"
                                width="640"
                                height="360"
                                src="https://www.youtube.com/embed/r_vjFGNedLk?controls=0&amp;rel=0&amp;playsinline=1&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fahpphysio.com.au&amp;widgetid=1"
                              ></iframe>*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-panel when-to">
              <div className="slider-panel-inner-wrapper">
                <div className="slider-panel-inner w100">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="section-heading mb-5">
                        <i className="far fa-clock"></i>&nbsp;When to use
                        SharePass
                      </h2>
                      <div className="col-md-12 module-fadein" data-delay="400">
                        <div className="row">
                          <div className="col-lg-1 col-md-1 col-sm-12 col-12"></div>

                          <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="counter mx-auto">
                              <div className="row">
                                <div className="col-md-12 mb-3 stats-icons-container">
                                  <i className="stats-icons fas fa-key fa-6x blue-color"></i>
                                </div>
                                <div className="col-md-12">
                                  <p className="count-text sharepass-font">
                                    PASSWORDS
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                            <div className="counter mx-auto">
                              <div className="row">
                                <div className="col-md-12 mb-3 stats-icons-container">
                                  <i className="stats-icons fas fa-envelope-open-text fa-6x red-color"></i>
                                </div>
                                <div className="col-md-12">
                                  <p className="count-text sharepass-font">
                                    SECRET NOTES
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                            <div className="counter mx-auto">
                              <div className="row">
                                <div className="col-md-12 mb-3 stats-icons-container">
                                  <i className="stats-icons fas fa-id-card fa-6x green-color"></i>
                                </div>
                                <div className="col-md-12">
                                  <p className="count-text sharepass-font">
                                    CREDENTIALS
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                            <div className="counter mx-auto">
                              <div className="row">
                                <div className="col-md-12 mb-3 stats-icons-container">
                                  <i className="stats-icons fas fa-qrcode fa-6x green-sust"></i>
                                </div>
                                <div className="col-md-12">
                                  <p className="count-text sharepass-font">
                                    QR Codes
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                            <div className="counter mx-auto">
                              <div className="row">
                                <div className="col-md-12 mb-3 stats-icons-container">
                                  <i className="stats-icons fas fa-code fa-6x yellow-color"></i>
                                </div>
                                <div className="col-md-12">
                                  <p className="count-text sharepass-font">
                                    JSON data
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-panel where-to bg-none">
              <div className="slider-panel-inner-wrapper">
                <div className="slider-panel-inner">
                  <div className="row mt-3">
                    <div className="col-md-12" data-delay="200">
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <div className="section-heading">
                            <h2 className="section-heading">
                              <i className="fas fa-cubes"></i>&nbsp;SUPPORTED
                              PLATFORMS
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div className="row d-none-m768">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                          <div
                            className="row module-fadein supported-platforms-container"
                            data-delay="200"
                          >
                            <div className="col-md-12">
                              <div className="row mb-2">
                                <div className="col-md-3"></div>
                                <div className="col-md-2">
                                  <div>
                                    <div className="text-center">
                                      <img
                                        height="50px"
                                        src="/assets/img/desktop.png"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div>
                                    <div className="text-center">
                                      <img
                                        height="50px"
                                        src="/assets/img/mobile.png"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div>
                                    <div className="text-center">
                                      <img
                                        height="50px"
                                        src="/assets/img/tablet.png"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div>
                                    <div className="text-center">
                                      <img
                                        height="50px"
                                        src="/assets/img/smart-watch.png"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-1"></div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-3 text-left pl-5">
                                  <span className="count-text sharepass-font white-color">
                                    WEB
                                  </span>
                                </div>
                                <div className="col-md-2">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-check-circle fa-2x green-color"></i>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-check-circle fa-2x green-color"></i>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-check-circle fa-2x green-color"></i>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-times-circle fa-2x red-color"></i>
                                  </div>
                                </div>
                                <div className="col-md-1"></div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-3 text-left pl-5">
                                  <span className="count-text sharepass-font white-color">
                                    APP
                                  </span>
                                </div>
                                <div className="col-md-2">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-times-circle fa-2x red-color"></i>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-check-circle fa-2x green-color"></i>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-check-circle fa-2x green-color"></i>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-check-circle fa-2x green-color"></i>
                                  </div>
                                </div>
                                <div className="col-md-1"></div>
                              </div>
                            </div>

                            <div className="col-md-12 mb-2">
                              <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                  <hr />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <span className="count-text sharepass-font white-color">
                                    EXTENSIONS &nbsp;
                                    <small>(coming soon)</small>
                                  </span>
                                </div>
                                <div className="col-md-4">
                                  <div className="text-right">
                                    <img
                                      width="20%"
                                      src="/assets/img/chrome.png"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="text-center">
                                    <img
                                      width="20%"
                                      src="/assets/img/edge.png"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="text-left">
                                    <img
                                      width="20%"
                                      src="/assets/img/firefox.png"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row d-only-m768">
                        <div className="col-12">
                          <div
                            className="row module-fadein supported-platforms-container"
                            data-delay="200"
                          >
                            <div className="col-12">
                              <div className="row mb-2">
                                <div className="col-4"></div>
                                <div className="col-4">WEB</div>
                                <div className="col-4">APP</div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-4">
                                  <div className="text-center">
                                    <img
                                      height="32px"
                                      src="/assets/img/desktop.png"
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-check-circle green-color"></i>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-times-circle red-color"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-4">
                                  <div className="text-center">
                                    <img
                                      height="32px"
                                      src="/assets/img/mobile.png"
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-check-circle green-color"></i>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-check-circle green-color"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-4">
                                  <div className="text-center">
                                    <img
                                      height="32px"
                                      src="/assets/img/tablet.png"
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-check-circle green-color"></i>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-check-circle green-color"></i>
                                  </div>
                                </div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-4">
                                  <div className="text-center">
                                    <img
                                      height="32px"
                                      src="/assets/img/smart-watch.png"
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-times-circle red-color"></i>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="text-center">
                                    <i className="status-indicator far fa-check-circle green-color"></i>
                                  </div>
                                </div>
                              </div>

                              <div className="row mb-2">
                                <div className="col-12">
                                  <hr />
                                </div>
                              </div>

                              <div className="row mb-2">
                                <div className="col-12 mb-2">
                                  <span className="count-text sharepass-font white-color">
                                    EXTENSIONS
                                    <small>(coming soon)</small>
                                  </span>
                                </div>
                                <div className="col-4">
                                  <div className="text-center">
                                    <img
                                      width="40%"
                                      src="/assets/img/chrome.png"
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="text-center">
                                    <img
                                      width="40%"
                                      src="/assets/img/edge.png"
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="text-center">
                                    <img
                                      width="40%"
                                      src="/assets/img/firefox.png"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="d-none">
          <div className="col-lg-12">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              preserveAspectRatio="none"
              viewBox="0 0 1680 40"
              style={{ width: "100%", marginBottom: "-201px" }}
            >
              <path
                d="M0 40h1680V30S1340 0 840 0 0 30 0 30z"
                fill="#000"
              ></path>
            </svg>
          </div>
        </div>
        <div id="sharepass-features"></div>

        <div className="d-none bg-mountains lh0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            preserveAspectRatio="none"
            viewBox="0 0 1680 40"
            style={{ width: "100%" }}
          >
            <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#000"></path>
          </svg>
        </div>

        <section id="features" className="features">
          <div className="section-heading text-center module-fadein">
            <h4 className="text-muted">&nbsp;</h4>
            <h2>Features</h2>
            <h4 className="text-muted">&nbsp;</h4>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid features-desktop">
                  <div className="row will-fadeIn">
                    <div className="col-md-4">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/one_time_icon.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>One time password</h3>
                          <p className="">
                            Auto destroy your secrets after being received
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/share_secure.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Share secure notes</h3>
                          <p className="">
                            Not only for passwords, share notes as well
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/web_hooks.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Webhooks</h3>
                          <p className="">
                            Receive notifications to your app when secrets are
                            opened
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row will-fadeIn">
                    <div className="col-md-4">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/time_to_live.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Time to live</h3>
                          <p className="">
                            You can set the desired expiration time for the
                            secrets
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/scheduled_secrets.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Scheduled secrets</h3>
                          <p className="">
                            Share a link that will be available based on your
                            time frame
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/full_api.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Full API</h3>
                          <p className="">
                            Fully integrate SharePass with business software
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container-fluid features-mobile">
                  <div className="row will-fadeIn">
                    <div className="col-6">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/one_time_icon.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>One time password</h3>
                          <p className="">
                            Auto destroy your secrets after being received
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/share_secure.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Share secure notes</h3>
                          <p className="">
                            Not only for passwords, share notes as well
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row will-fadeIn">
                    <div className="col-6">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/web_hooks.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Webhooks</h3>
                          <p className="">
                            Receive notifications to your app when secrets are
                            opened
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/time_to_live.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Time to live</h3>
                          <p className="">
                            You can set the desired expiration time for the
                            secrets
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row will-fadeIn">
                    <div className="col-6">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/scheduled_secrets.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Scheduled secrets</h3>
                          <p className="">
                            Share a link that will be available based on your
                            time frame
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/full_api.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Full API</h3>
                          <p className="">
                            Fully integrate SharePass with business software
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div>
        <Helmet>
          <meta
            property="og:title"
            content="SharePass | Don't risk it, SharePass it!"
          />
        </Helmet>

        <div id="sharepass-explainer"></div>
        <section id="explainer" className="explainer">
          <div className="container">
            <div className="row">
              <div className="col-md-12 module-fadein" data-delay="400">
                <div className="section-heading text-center mb-3">
                  <h2>Explainer</h2>
                </div>

                <iframe
                  className="video-explainer"
                  src="https://www.youtube.com/embed/EdC-ctCGwy8?rel=0"
                  frameBorder="0"
                  title="Explainer"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen={true}
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <div id="sharepass-about"></div>
        <section className="text-center" id="sharepass">
          {/*<Controller>
    
                        <Scene
                            triggerHook="onLeave"
                            duration="1800%"
                            pin
                        >
    
                            <Timeline
                                wrapper={<div id="sharepassSlider" />}
                            >
    
                                <Tween
                                    to={{ x: "-500vw" }}
                    >*/}
          <div className="slider-panel statistics-home">
            <div className="slider-panel-inner-wrapper">
              <div className="slider-panel-inner">
                <div className="col-md-12 d-none" data-delay="200">
                  <div className="section-heading">
                    <h2 className="section-heading">
                      <i className="fas fa-chart-pie"></i>&nbsp;Statistics
                    </h2>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div className="col-md-12 module-fadein" data-delay="400">
                  <div className="row">
                    <div className="col-md-4 col-6">
                      <div className="counter mx-auto">
                        <div className="row">
                          <div className="col-md-12 mb-3 stats-icons-container">
                            <i className="stats-icons fas fa-leaf fa-6x green-sust"></i>
                          </div>
                          <div className="col-md-12">
                            <h2
                              id="operationsTime"
                              className=" count-title count-number"
                            ></h2>
                            <p className="count-text sharepass-font">
                              ENERGY EFFICIENCY
                            </p>
                            <p className="green-sust d-none-m768">
                              SharePass reduces energy consumption by running
                              its core services on demand
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-6">
                      <div className="counter mx-auto">
                        <div className="row">
                          <div className="col-md-12 mb-3 stats-icons-container">
                            <i className="stats-icons fas fa-unlock-alt fa-6x"></i>
                          </div>
                          <div className="col-md-12">
                            <h2
                              id="secretsSharePassed"
                              className="timer count-title count-number"
                              data-to="100"
                              data-speed="1500"
                            ></h2>
                            <p className="count-text sharepass-font">
                              SECRETS SHAREPASSED
                            </p>
                            <p className="white-color d-none-m768">
                              Total secrets generated and delivered using
                              SharePass
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mmt-3">
                      <div className="row">
                        <div className="col-3"></div>
                        <div className="col-md-12 col-6">
                          <div className="counter mx-auto">
                            <div className="row">
                              <div className="col-md-12 mb-3 stats-icons-container">
                                <i className="stats-icons fas fa-database fa-6x"></i>
                              </div>
                              <div className="col-md-12">
                                <h2 id="dataSharePassed" className=""></h2>
                                <p className="count-text sharepass-font">
                                  DATA SHAREPASSED
                                </p>
                                <p className="white-color d-none-m768">
                                  Total data transferred using SharePass (On
                                  average, a 10 characters secret is 270 bytes)
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-panel about-business bg-none">
            <div className="slider-panel-inner-wrapper">
              <div className="slider-panel-inner">
                <div className="row">
                  <div className="col-md-1"></div>

                  <div className="col-md-10">
                    <div className="text-box-style">
                      <h2 className="section-heading">About the business</h2>
                      <p className="white-color">
                        SharePass is an online digital security solution for
                        confidential information sharing.
                      </p>
                      <p className="white-color">
                        SharePass was established in 2020 by 3 security
                        engineers.
                      </p>
                      <p className="white-color">
                        "We recognised a real need to communicate securely,
                        easily!".
                      </p>
                      <p className="white-color">
                        For <b style={{ fontWeight: "bolder" }}>individuals</b>,
                        it allows transferring confidential data safely without
                        leaving a{" "}
                        <a
                          href="#"
                          onClick={() => {
                            return false;
                          }}
                          className="footprint-tooltip"
                          title="Digital footprint refers to the information about a particular person that exists on the internet as a result of their online activity."
                          data-toggle="tooltip"
                        >
                          digital footprint
                        </a>
                        .
                      </p>
                      <p className="white-color">
                        For <b style={{ fontWeight: "bolder" }}>business</b>, it
                        allows the same plus secure communication, credentials
                        protection and overall improvement of the security
                        profile.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-panel why-sharepass">
            <div className="slider-panel-inner-wrapper">
              <div className="slider-panel-inner">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="section-heading">
                      <i className="far fa-question-circle"></i>&nbsp;Why you
                      should use SharePass
                    </h2>

                    <p className="white-color">
                      When you share confidential information over traditional
                      channels such as notes, SMS, chatting tools, or emails,
                      you leave a{" "}
                      <a
                        href="#"
                        onClick={() => {
                          return false;
                        }}
                        className="footprint-tooltip"
                        title="Digital footprint refers to the information about a particular person that exists on the internet as a result of their online activity."
                        data-toggle="tooltip"
                      >
                        digital footprint
                      </a>
                      . Unknowingly, this footprint remains available in clear
                      text, allowing it to be discovered by hackers and other
                      users.
                    </p>

                    <p className="white-color">
                      When using SharePass, you can securely share the required
                      information by encrypting it and sending the link to the
                      recipient. Once the recipient has opened the link, it
                      becomes inactive, leaving no traces of your confidential
                      data.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-panel how-to bg-none">
            <div className="slider-panel-inner-wrapper">
              <div className="slider-panel-inner">
                <div className="row">
                  <div className="col-md-12" data-delay="200">
                    <div className="row mt-2 mb-2">
                      <div className="col-md-12">
                        <div className="section-heading">
                          <h2 className="section-heading">
                            <i className="far fa-question-circle"></i>&nbsp;How
                            to use SharePass
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <ul id="how-to-list">
                          <li>
                            <p>
                              <i className="fas fa-comment-dots"></i>&nbsp; Type
                              your secret message
                            </p>
                          </li>
                          <li>
                            <p>
                              <i className="fas fa-key"></i>&nbsp; Click{" "}
                              <b>SharePass it!</b> button
                            </p>
                          </li>
                          <li>
                            <p>
                              <i className="far fa-copy"></i>&nbsp; Copy the
                              link generated
                            </p>
                          </li>
                          <li>
                            <p className="mb-0">
                              <i className="fas fa-share-alt-square"></i>&nbsp;
                              Share the link
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-lg-12">
                            <video
                              id="demo-video"
                              width="100%"
                              autoPlay={true}
                              muted={true}
                              loop={true}
                              playsInline={true}
                            >
                              <source
                                src="/assets/videos/SP_demo.mp4"
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-panel when-to">
            <div className="slider-panel-inner-wrapper">
              <div className="slider-panel-inner w100">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="section-heading mb-5">
                      <i className="far fa-clock"></i>&nbsp;When to use
                      SharePass
                    </h2>
                    <div className="col-md-12 module-fadein" data-delay="400">
                      <div className="row">
                        <div className="col-lg-1 col-md-1 col-sm-12 col-12"></div>

                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                          <div className="counter mx-auto">
                            <div className="row">
                              <div className="col-md-12 mb-3 stats-icons-container">
                                <i className="stats-icons fas fa-key fa-6x blue-color"></i>
                              </div>
                              <div className="col-md-12">
                                <p className="count-text sharepass-font">
                                  PASSWORDS
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                          <div className="counter mx-auto">
                            <div className="row">
                              <div className="col-md-12 mb-3 stats-icons-container">
                                <i className="stats-icons fas fa-envelope-open-text fa-6x red-color"></i>
                              </div>
                              <div className="col-md-12">
                                <p className="count-text sharepass-font">
                                  SECRET NOTES
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                          <div className="counter mx-auto">
                            <div className="row">
                              <div className="col-md-12 mb-3 stats-icons-container">
                                <i className="stats-icons fas fa-id-card fa-6x green-color"></i>
                              </div>
                              <div className="col-md-12">
                                <p className="count-text sharepass-font">
                                  CREDENTIALS
                                  <br />
                                  <small>(Coming soon)</small>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                          <div className="counter mx-auto">
                            <div className="row">
                              <div className="col-md-12 mb-3 stats-icons-container">
                                <i className="stats-icons fas fa-qrcode fa-6x green-sust"></i>
                              </div>
                              <div className="col-md-12">
                                <p className="count-text sharepass-font">
                                  QR Codes
                                  <br />
                                  <small>(Coming soon)</small>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-6 col-6 mb-3">
                          <div className="counter mx-auto">
                            <div className="row">
                              <div className="col-md-12 mb-3 stats-icons-container">
                                <i className="stats-icons fas fa-code fa-6x yellow-color"></i>
                              </div>
                              <div className="col-md-12">
                                <p className="count-text sharepass-font">
                                  JSON data
                                  <br />
                                  <small>(Coming soon)</small>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="slider-panel where-to bg-none">
            <div className="slider-panel-inner-wrapper">
              <div className="slider-panel-inner">
                <div className="row mt-3">
                  <div className="col-md-12" data-delay="200">
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <div className="section-heading">
                          <h2 className="section-heading">
                            <i className="fas fa-cubes"></i>&nbsp;SUPPORTED
                            PLATFORMS
                          </h2>
                        </div>
                      </div>
                    </div>

                    <div className="row d-none-m768">
                      <div className="col-md-1"></div>
                      <div className="col-md-10">
                        <div
                          className="row module-fadein supported-platforms-container"
                          data-delay="200"
                        >
                          <div className="col-md-12">
                            <div className="row mb-2">
                              <div className="col-md-3"></div>
                              <div className="col-md-2">
                                <div>
                                  <div className="text-center">
                                    <img
                                      height="50px"
                                      src="/assets/img/desktop.png"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div>
                                  <div className="text-center">
                                    <img
                                      height="50px"
                                      src="/assets/img/mobile.png"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div>
                                  <div className="text-center">
                                    <img
                                      height="50px"
                                      src="/assets/img/tablet.png"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div>
                                  <div className="text-center">
                                    <img
                                      height="50px"
                                      src="/assets/img/smart-watch.png"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1"></div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-3 text-left pl-5">
                                <span className="count-text sharepass-font white-color">
                                  WEB
                                </span>
                              </div>
                              <div className="col-md-2">
                                <div className="text-center">
                                  <i className="status-indicator far fa-check-circle fa-2x green-color"></i>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="text-center">
                                  <i className="status-indicator far fa-check-circle fa-2x green-color"></i>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="text-center">
                                  <i className="status-indicator far fa-check-circle fa-2x green-color"></i>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="text-center">
                                  <i className="status-indicator far fa-times-circle fa-2x red-color"></i>
                                </div>
                              </div>
                              <div className="col-md-1"></div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-3 text-left pl-5">
                                <span className="count-text sharepass-font white-color">
                                  APP
                                  <small>(coming soon)</small>
                                </span>
                              </div>
                              <div className="col-md-2">
                                <div className="text-center">
                                  <i className="status-indicator far fa-times-circle fa-2x red-color"></i>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="text-center">
                                  <i className="status-indicator far fa-check-circle fa-2x green-color"></i>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="text-center">
                                  <i className="status-indicator far fa-check-circle fa-2x green-color"></i>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="text-center">
                                  <i className="status-indicator far fa-check-circle fa-2x green-color"></i>
                                </div>
                              </div>
                              <div className="col-md-1"></div>
                            </div>
                          </div>

                          <div className="col-md-12 mb-2">
                            <div className="row">
                              <div className="col-md-1"></div>
                              <div className="col-md-10">
                                <hr />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <span className="count-text sharepass-font white-color">
                                  EXTENSIONS
                                  <small>(coming soon)</small>
                                </span>
                              </div>
                              <div className="col-md-4">
                                <div className="text-right">
                                  <img
                                    width="20%"
                                    src="/assets/img/chrome.png"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="text-center">
                                  <img width="20%" src="/assets/img/edge.png" />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="text-left">
                                  <img
                                    width="20%"
                                    src="/assets/img/firefox.png"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row d-only-m768">
                      <div className="col-12">
                        <div
                          className="row module-fadein supported-platforms-container"
                          data-delay="200"
                        >
                          <div className="col-12">
                            <div className="row mb-2">
                              <div className="col-4"></div>
                              <div className="col-4">WEB</div>
                              <div className="col-4">APP</div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4">
                                <div className="text-center">
                                  <img
                                    height="32px"
                                    src="/assets/img/desktop.png"
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="text-center">
                                  <i className="status-indicator far fa-check-circle green-color"></i>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="text-center">
                                  <i className="status-indicator far fa-times-circle red-color"></i>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4">
                                <div className="text-center">
                                  <img
                                    height="32px"
                                    src="/assets/img/mobile.png"
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="text-center">
                                  <i className="status-indicator far fa-check-circle green-color"></i>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="text-center">
                                  <i className="status-indicator far fa-check-circle green-color"></i>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4">
                                <div className="text-center">
                                  <img
                                    height="32px"
                                    src="/assets/img/tablet.png"
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="text-center">
                                  <i className="status-indicator far fa-check-circle green-color"></i>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="text-center">
                                  <i className="status-indicator far fa-check-circle green-color"></i>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-4">
                                <div className="text-center">
                                  <img
                                    height="32px"
                                    src="/assets/img/smart-watch.png"
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="text-center">
                                  <i className="status-indicator far fa-times-circle red-color"></i>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="text-center">
                                  <i className="status-indicator far fa-check-circle green-color"></i>
                                </div>
                              </div>
                            </div>

                            <div className="row mb-2">
                              <div className="col-12">
                                <hr />
                              </div>
                            </div>

                            <div className="row mb-2">
                              <div className="col-12 mb-2">
                                <span className="count-text sharepass-font white-color">
                                  EXTENSIONS
                                  <small>(coming soon)</small>
                                </span>
                              </div>
                              <div className="col-4">
                                <div className="text-center">
                                  <img
                                    width="40%"
                                    src="/assets/img/chrome.png"
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="text-center">
                                  <img width="40%" src="/assets/img/edge.png" />
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="text-center">
                                  <img
                                    width="40%"
                                    src="/assets/img/firefox.png"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*</Tween>
                            </Timeline>
    
                        </Scene>
                                </Controller>*/}
        </section>
        <div className="d-none">
          <div className="col-lg-12">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              preserveAspectRatio="none"
              viewBox="0 0 1680 40"
              style={{ width: "100%", marginBottom: "-201px" }}
            >
              <path
                d="M0 40h1680V30S1340 0 840 0 0 30 0 30z"
                fill="#000"
              ></path>
            </svg>
          </div>
        </div>
        <div id="sharepass-features"></div>

        <div className="d-none bg-mountains lh0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            preserveAspectRatio="none"
            viewBox="0 0 1680 40"
            style={{ width: "100%" }}
          >
            <path d="M0 40h1680V30S1340 0 840 0 0 30 0 30z" fill="#000"></path>
          </svg>
        </div>

        <section id="features" className="features">
          <div className="section-heading text-center module-fadein">
            <h2>Features</h2>
            <h4 className="text-muted">(Coming soon)</h4>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid features-desktop">
                  <div className="row will-fadeIn">
                    <div className="col-md-4">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/one_time_icon.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>One time password</h3>
                          <p className="">
                            Auto destroy your secrets after being received
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/share_secure.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Share secure notes</h3>
                          <p className="">
                            Not only for passwords, share notes as well
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/web_hooks.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Webhooks</h3>
                          <p className="">
                            Receive notifications to your app when secrets are
                            opened
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row will-fadeIn">
                    <div className="col-md-4">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/time_to_live.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Time to live</h3>
                          <p className="">
                            You can set the desired expiration time for the
                            secrets
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/scheduled_secrets.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Scheduled secrets</h3>
                          <p className="">
                            Share a link that will be available based on your
                            time frame
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/full_api.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Full API</h3>
                          <p className="">
                            Fully integrate SharePass with business software
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container-fluid features-mobile">
                  <div className="row will-fadeIn">
                    <div className="col-6">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/one_time_icon.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>One time password</h3>
                          <p className="">
                            Auto destroy your secrets after being received
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/share_secure.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Share secure notes</h3>
                          <p className="">
                            Not only for passwords, share notes as well
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row will-fadeIn">
                    <div className="col-6">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/web_hooks.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Webhooks</h3>
                          <p className="">
                            Receive notifications to your app when secrets are
                            opened
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/time_to_live.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Time to live</h3>
                          <p className="">
                            You can set the desired expiration time for the
                            secrets
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row will-fadeIn">
                    <div className="col-6">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/scheduled_secrets.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Scheduled secrets</h3>
                          <p className="">
                            Share a link that will be available based on your
                            time frame
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="feature-item">
                        <div className="feature-img-container">
                          <img src="/assets/img/full_api.jpg" />
                        </div>
                        <div className="feature-desc">
                          <h3>Full API</h3>
                          <p className="">
                            Fully integrate SharePass with business software
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
