import { Helmet } from "react-helmet";
function PrivacyPolicy() {
  return (
    <section className="page-container">
      <Helmet>
        <title>Privacy policy | SharePass</title>
        <meta
          property="og:title"
          content="SharePass | Privacy policy | Don't risk it, SharePass it!"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <h1 className="text-center section-heading"> Privacy policy </h1>

            <p>
              Protection of your personal information is important to
              SharePass.&nbsp;
            </p>

            <p>
              The way in which&nbsp; SharePass Pty Ltd (ACN 647 015 601)&nbsp;
              (“we”, “us”, “our”) collect, use, store and disclose personal
              information in accordance with our obligations under the Privacy
              Act, including those contained in the Australian Privacy
              Principles (<strong>APP</strong>) is set out below.&nbsp;
            </p>

            <p>
              This{" "}
              <a href="https://sharepass.com/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>{" "}
              also constitutes a collection statement for the purposes of the
              Privacy Act. Privacy Act means the <em>Privacy Act 1988</em> (Cth)
              including the APP (as amended from time to time).
            </p>

            <p>
              Personal Information has the meaning given under the Privacy Act
              but, in short, means information or an opinion relating to an
              individual that can be used to identify that individual.
            </p>

            <p>
              By visiting the Website, downloading our Application(s), and/or
              otherwise accessing and/or using our Service, you are agreeing to
              the terms of this{" "}
              <a href="https://sharepass.com/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>{" "}
              and the accompanying&nbsp;
              <strong>
                <a href="/terms-of-service">Terms of service</a>,
              </strong>{" "}
              which incorporate this{" "}
              <a href="https://sharepass.com/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>{" "}
              and the{" "}
              <a href="/permitted-use-policy">
                <strong>Permitted Use Policy</strong>
              </a>
              &nbsp;and{" "}
              <strong>
                <a href="/cookies-policy">Cookies Policy</a>
              </strong>
              <strong> </strong>by reference.
            </p>

            <p>
              Capitalised terms not defined in this{" "}
              <a href="https://sharepass.com/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>{" "}
              shall have the meanings set forth in the{" "}
              <strong>
                <a href="/terms-of-service">Terms of service</a>
              </strong>
              .
            </p>

            <p>
              This{" "}
              <a href="https://sharepass.com/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>{" "}
              applies to information we collect through any version of the
              Service and to information you send to us. It does not apply to
              information collected by any third party, including through any
              application, content, link or other interaction that may be
              accessible to you through the Service.
            </p>

            <p>
              We only collect information that is reasonably necessary for the
              proper performance of our activities or functions and
              Services.&nbsp; We do not collect personal information just
              because we think it could be useful at some future stage if we
              have no present need for it.&nbsp; We may decline to collect
              unsolicited personal information from or about you and take steps
              to purge it from our systems.
            </p>

            <p>
              We manage personal information as if we are an APP Entity under
              the APPs.
            </p>

            <p>
              <strong>
                What Personal Information do we collect and why do we need it
              </strong>
            </p>

            <p>
              To provide you with access to the Website, Application and our
              Services, we need to collect Personal Information.&nbsp; If any of
              the Personal Information you provide is incomplete or inaccurate,
              we may be unable to provide Services to you or the quality of
              those Services we provide may be compromised. If you do not agree,
              you may not use the Service.
            </p>

            <p>
              The nature of the relationship we have with you and the Service
              you require from us will determine the personal and other
              information we collect.&nbsp;
            </p>

            <p>Accordingly:&nbsp;</p>

            <ul>
              <li>
                we determine that it is reasonably necessary for our functions
                or activities in respect of the Service being provided;&nbsp;
              </li>
              <li>
                we attempt to cross-check the information that we collect from
                you with third parties;
              </li>
              <li>
                we record and hold your information in our Systems. Some
                information may be disclosed to third party or overseas
                recipients;
              </li>
              <li>
                we retrieve your information when we need to use or disclose it
                for our functions and activities as relevant to our Service;
              </li>
              <li>
                subject to some exceptions, we permit you to access your
                Personal Information in accordance with the APPs.
              </li>
              <li>
                we correct or attach associated statements to your Personal
                Information in accordance with the APPs.
              </li>
            </ul>

            <p>Personal Information may include:</p>

            <ul>
              <li>
                contact and identity information (for example, your name, email
                address, telephone numbers, address, social media handles, date
                of birth and educational qualifications and similar information
                or other information in a registration form);
              </li>
              <li>
                Personal Information (including Sensitive Information) which is
                provided to us in the course of Services by us to you or a third
                party;
              </li>
              <li>
                data stored in the address book on the Device from which you are
                accessing and using the Service (but processed in
                hashed/anonymised form (i.e., in a manner in which we cannot
                read the data);
              </li>
              <li>
                temporarily process and store your Message (in encrypted form),
                log and contact data, and other related information (we do not
                (and cannot) read your encrypted Messages, and we delete your
                Messages as soon as they have been successfully read or, in the
                case of an unread message, after several days have elapsed;
              </li>
              <li>
                your IP address, operating system and language, information
                about your Device, including Device model and manufacturer,
                universally unique ID and/or mobile advertiser ID, screen size
                and resolution, carrier and network status, country and time
                zone, storage and processor information;
              </li>
              <li>bank details and expiry dates;</li>
              <li>feedback that you may have provided.</li>
            </ul>

            <p>
              “Sensitive Information” has the same meaning as under the Privacy
              Act.
            </p>

            <p>
              <strong>How do we collect Personal Information?</strong>
            </p>

            <p>
              We aim to collect Personal Information directly from you
              electronically through our website.&nbsp;
            </p>

            <p>
              However, we may also collect Personal Information about you when
              we, for example:&nbsp;
            </p>

            <ul>
              <li>provide our Services to you; and</li>
              <li>
                receive performance feedback (whether positive or negative) from
                any person.
              </li>
            </ul>

            <p>We may also collect Personal Information:</p>

            <ul>
              <li>from automated data collection technologies;</li>
              <li>from parties to whom we refer you;</li>
              <li>from credit reporting and fraud checking agencies;</li>
              <li>
                from debt collection agencies if you default in a payment to us;
              </li>
              <li>
                through our website and by other electronic communication
                channels;
              </li>
              <li>any third-party applications;</li>
              <li>from your spouse, partner or dependants;</li>
              <li>from third parties;</li>
              <li>
                from publicly available sources of information (including but
                not limited to newspapers, journals, directories, the Internet
                and social media sites);
              </li>
              <li>
                when we are permitted or required to do so by law (including
                under the Privacy Act).
              </li>
            </ul>

            <p>
              If you, your friends, or members of your network use any
              third-party applications, you consent to those applications
              accessing and sharing certain personal information about you with
              others in accordance with your privacy settings. We do not screen
              or approve developers of applications and cannot control how those
              developers use any personal information that they may obtain in
              connection with applications.
            </p>

            <p>
              <strong>How do we use your Personal Information?</strong>
            </p>

            <p>
              We use the Personal Information we collect, hold use and disclose
              for the purpose of providing our Services.
            </p>

            <p>We may also use Personal Information:</p>

            <ul>
              <li>
                to comply with our contractual and other legal obligations
                (including but not limited to customer support and information
                on our Services, newsletters email updates, subscriptions and
                other services);
              </li>
              <li>
                to verify your identity, including during account creation and
                password reset processes and to pursue other activities within
                our legitimate interests as part of running our business and
                doing so in ways which do not hurt your interests and rights,
                for example obtaining identity, device and location information
                to prevent fraud and abuse and to keep the applications secure
                and obtaining and retaining data which tax laws require us to
                retain for payments made or received by us;
              </li>
              <li>
                to confirm your identity for the purposes of the{" "}
                <em>
                  Anti-Money Laundering and Counter-Terrorism Financing Act 2006
                </em>{" "}
                (Cth) and assess applications or creditworthiness for premier or
                business accounts or products and services offered to premier or
                business accounts with third parties;
              </li>
              <li>
                to customise, measure, and improve the content, layout, and
                operation of the applications and related services;
              </li>
              <li>
                to contact you at any telephone number, or by placing a voice
                call or through text (SMS) or email messaging;
              </li>
              <li>
                to manage and protect our information technology infrastructure,
                which is necessary to provide the services and features you
                request and serve the legitimate interests of ourselves and
                third party processors;
              </li>
              <li>
                to share minimal personal information and data with third party
                processors in order for us to provide services under our
                contract with you, including service providers of hosting,
                storage and infrastructure, security, insurance, analytics,
                communications and support, and payment processing;
              </li>
              <li>
                to perform creditworthiness and solvency checks, compare
                information for accuracy and verify it with third parties;
              </li>
              <li>to design new products and services;</li>
              <li>
                to provide to you targeted marketing about our Services and
                promotional offers and the services of our contractors unless
                and until you opt-out;
              </li>
              <li>
                to include you in our databases, directories, listings and
                digital applications to communicate with you and indicate your
                affiliation with us;
              </li>
              <li>
                to process any transactions by you and send notices about your
                transactions;
              </li>
              <li>
                to resolve disputes, collect fees, and troubleshoot problems;
              </li>
              <li>
                to detect, prevent or resolve violations of policies or
                applicable user agreements; and
              </li>
              <li>
                to manage our risks and help detect, prevent, and/or remediate
                fraud or other potentially illegal or prohibited activities.
              </li>
              <li>for insurance purposes;</li>
              <li>
                for statistical purposes and statutory compliance requirements;
              </li>
              <li>
                for improving our client service, including customising our
                website in order to better suit your&nbsp;requirements;
              </li>
              <li>for responding to enquiries or questions from you;</li>
              <li>for training;&nbsp;</li>
              <li>for risk management</li>
              <li>for any client and business relationship management;</li>
              <li>to confirm identity and authority to provide references;</li>
              <li>
                subject to you advising us otherwise (see Marketing below), to
                advise you of additional services or information which may be of
                interest to you; and
              </li>
              <li>otherwise as permitted under the Privacy Act.</li>
            </ul>

            <p>
              If you default in a payment to us, we may use your Personal
              Information to recover that debt.
            </p>

            <p>
              We provide services in conjunction with payment processors which
              may include processors such as financial or merchant facility
              providers (such as Paypal, Stripe etc). When you delete your
              account with us, we delete your personal data retained by us,
              except to the extent we are prevented by law from deleting your
              personal information or data. However, to delete any payment or
              billing information, you will need to do so with your payment
              provider, as we only have minimal secure access to those records
              as needed to provide the services.
            </p>

            <p>
              <strong>Marketing</strong>
            </p>

            <p>
              We do not provide your Personal Information to any other person
              for marketing purposes. Except as specified in this Privacy
              Policy, we will not sell your personal information.
            </p>

            <p>
              From time to time, we may use your Personal Information to provide
              you with information on any relevant industry developments,
              including sending you email alerts, offers for services,
              materials, promotions and competitions, campaigns to participate
              in and to communicate any other relevant information.&nbsp; We do
              not, however, use Sensitive Information for this purpose.
            </p>

            <p>
              If you no longer wish to receive these alerts, unsubscribe links
              are provided in every email that you receive.&nbsp; If at any time
              you no do not wish to receive any additional material from us,
              contact our Privacy Officer and we will remove your details from
              our marketing database.
            </p>

            <p>
              <strong>Anonymous Dealings&nbsp;</strong>
            </p>

            <p>
              You are not obliged to give us your Personal Information. If you
              would like to access any of our Services on an anonymous basis,
              please advise us in writing. If it is possible and lawful, we will
              take reasonable steps to comply with your request. However, if you
              choose not to provide us with some or all of your Personal
              Information, we may not be able to provide you with some part or
              all of our Services (or our assistance will be restricted at our
              discretion).
            </p>

            <p>
              <strong>Disclosing Personal Information&nbsp;</strong>
            </p>

            <p>
              <strong>Disclosure to Third Parties</strong>
            </p>

            <p>
              We may disclose your Personal Information to third parties in
              certain circumstances including:
            </p>

            <ul>
              <li>if you agree to the disclosure;</li>
              <li>
                when we use it for the purpose for which it was collected (e.g.,
                to provide you with Services);
              </li>
              <li>between our related entities;</li>
              <li>your spouse or partner;</li>
              <li>
                in circumstances where you would reasonably expect information
                of that kind to be passed to a third party;
              </li>
              <li>
                our contracted software solutions providers; IT contractors and
                database designers and Internet service suppliers; Legal and
                other professional advisors; Insurance brokers, loss assessors
                and underwriters;&nbsp;
              </li>
              <li>
                where disclosure is required or permitted by law (including
                under the Privacy Act), by court order, or is required to
                investigate suspected fraud or other unlawful activity; or
              </li>
              <li>
                if disclosure will prevent or lessen a serious or imminent
                threat to someone’s life or health.
              </li>
            </ul>

            <p>
              <strong>External Sites</strong>
            </p>

            <p>
              Please note we are not responsible for the practices or content of
              other services or sites that are operated by third parties but
              which are linked to our website. If we are linked to third party
              services or sites, it does not necessarily constitute sponsorship,
              endorsement or approval by the website of the content, policies or
              practices of those services or sites. If you leave our website via
              such a link, you should check the privacy policy of the third
              party site.
            </p>

            <p>
              We may use third parties to manage the website and activities from
              time to time, including, but not limited to, promotions and
              statistical analysis. These parties may have access to and store
              the personal information of users of the website. Similarly, we
              may contract with third parties to handle requests for information
              submitted to us.
            </p>

            <p>
              If you become a registered user of any of third party
              applications, you will be taken to consent to:
            </p>

            <ul>
              <li>
                your personal data being transferred to and processed in
                countries where our hosting servers are located;
              </li>
              <li>
                us using your personal information to check your identity,
                including to check your status (e.g. as a member of a defined
                user group) and for any merchant facility arrangement we have
                with bankers, their respective related bodies corporate, and
                their respective officers, employees and agents;
              </li>
              <li>
                use of your personal information by us and our authorised
                personnel and service provider contractors; for example, to
                improve service we may provide your personal information as
                statistical data to others for research and analysis purposes;
                and as another example we may use Google Analytics and give you
                notice of its operation on the webpage titled “How Google uses
                data when you use our partners' sites or apps” (located at&nbsp;
                <a href="https://www.google.com/policies/privacy/partners/">
                  google.com/policies/privacy/partners/
                </a>
                ).
              </li>
              <li>
                us disclosing your personal information in situations where we
                reasonably believe it is necessary to identify, contact or bring
                legal action against anyone damaging, injuring, or interfering
                with our rights, either intentionally or unintentionally; and
              </li>
              <li>
                us disclosing your personal information to law enforcement
                agencies and others if we in good faith believe we are legally
                obligated to do so.
              </li>
            </ul>

            <p>
              <strong>Children under the age of 18</strong>
            </p>

            <p>
              We do not knowingly collect or solicit personal information from
              anyone under the age of 18 (or the age of majority in your
              jurisdiction) or knowingly allow such persons to register. If you
              are under 18, please do not attempt to register or send any
              information about yourself to us, including your name, address,
              telephone number, or email address. No one under age 18 is
              permitted to provide any personal information. If we learn that we
              have collected personal information from a child under age 18
              without verification of parental consent, we will delete that
              information as quickly as possible. If you believe that we might
              have any information from or about a child under 18, please
              contact us at{" "}
              <a href="mailto:legal@sharepass.com">legal@sharepass.com</a>&nbsp;
            </p>

            <p>
              <strong>Business Transfer</strong>
            </p>

            <p>
              If the ownership changes of all or a substantial part of our
              business, or individual business units, your Persona Information
              (including your profile with the website) may be transferred to
              the new owner so the service or the transferred part of it can
              continue operations. In any such transfer of information, your
              personal information would remain subject to this{" "}
              <a href="https://sharepass.com/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>{" "}
              at the time of the transfer and then to the privacy policy of the
              new owner.
            </p>

            <p>
              <strong>
                Considerations when you send Information to Us Electronically
              </strong>
            </p>

            <p>
              If you send an email to us (including any emails addressed to us
              the information in your email (including any Personal Information)
              may be retained on our systems in accordance with applicable email
              retention policies and procedures.
            </p>

            <p>
              While we do all we reasonably can to protect your Personal
              Information from misuse, loss, unauthorised access, modification
              or disclosure, including investing in security software, no data
              transfer over the Internet is 100% secure.
            </p>

            <p>
              If you access another website from our website, you do so and
              provide personal information in accordance with the terms and
              conditions under which the provider of that website operates.
            </p>

            <p>
              The open nature of the Internet is such that information exchanged
              via the internet may be accessed and used by people other than
              those for whom the data is intended. If you send us any
              information, including (without limitation) Personal Information,
              is sent through the Internet or other electronic means at your own
              risk.
            </p>

            <p>
              While we are not in a position to give you advice on internet
              security, if you provide Personal Information to us
              electronically, there are some things you can do which may help
              maintain the privacy of your information, including:
            </p>

            <ul>
              <li>
                always closing your browser when you have finished your session;
                and
              </li>
              <li>
                never providing Personal Information when using a public
                computer.
              </li>
            </ul>

            <p>You should contact us immediately if you believe:</p>

            <ul>
              <li>
                someone has gained access to Personal Information you have
                provided to us;
              </li>
              <li>
                we have breached our privacy obligations or your privacy rights
                in any way; or
              </li>
              <li>
                you would like to discuss any issues about our{" "}
                <a href="https://sharepass.com/privacy-policy">
                  <strong>Privacy Policy</strong>
                </a>
                .
              </li>
            </ul>

            <p>
              <strong>
                How we act to protect and retain your Personal Information
              </strong>
            </p>

            <p>
              We endeavour to keep our information systems and files secured
              from unauthorised access.&nbsp; Those who work with us are aware
              of the legal obligations in respect to confidentiality and the
              importance we place on protecting your privacy.
            </p>

            <p>
              Our procedures to securely store Personal Information include
              electronic and physical security measures, staff training and use
              of password protection software.
            </p>

            <p>
              We retain our files for at least seven years.&nbsp; However,
              information filed in our electronic files may be retained
              indefinitely.
            </p>

            <p>
              Personal Information may also be retained if we consider it
              necessary to do so or to comply with any applicable law or our
              insurance, governance obligations in our IT back-up records, for
              the collection of any monies owed and to resolve disputes.
            </p>

            <p>
              <strong>
                How you can update or correct your Personal Information
              </strong>
            </p>

            <p>
              You may request access to your Personal Information or correct any
              inaccurate or out of date information by contacting our Privacy
              Officer using the details below.&nbsp;&nbsp;
            </p>

            <p>
              For security purposes, before we provide you with personal
              information, we may ask you to provide evidence of your identity.
            </p>

            <p>
              You may request the source of any information we collect from a
              third party.&nbsp; We will provide this at no cost, unless under
              the Privacy Act or other law there is a reason for this
              information being withheld.
            </p>

            <p>
              If there is a reason under the Privacy Act or other law for us not
              to provide you with information, we will give you a written notice
              of refusal setting out the reasons for the refusal except to the
              extent it would be unreasonable to do so and the mechanisms
              available to you to complain about the refusal.
            </p>

            <p>
              If you provide us with your personal information and you later
              decided that you would no longer like to receive information from
              us, follow the "unsubscribe" directions at the end of any email or
              other communication you receive from us. You may withdraw consent
              by deleting your account at any time.
            </p>

            <p>
              <strong>
                How to complain if you believe we have breached the Australian
                Privacy Principles (APP)
              </strong>
            </p>

            <p>
              If you believe that we have dealt with your Personal Information
              in a way that is inconsistent with the APP, you should contact our
              Privacy Officer in the first instance using the details
              below.&nbsp;&nbsp;
            </p>

            <p>We will attempt to respond to your complaint within 30 days.</p>

            <p>
              If we are unable to resolve your complaint you may contact the
              Office of the Australian Information Commissioner (contact details
              are below).
            </p>

            <p>
              <strong>Important Notice to Non-Australian Residents</strong>
            </p>

            <p>
              If you are located outside the location of servers, please be
              aware that any information provided to us, including personal
              information, will be transferred from your country of origin to
              Australia.&nbsp;
            </p>

            <p>
              You may have additional rights under applicable data protection
              laws, including the EU General Data Protection Regulation (
              <strong>GDPR</strong>). For example, you may have the right to
              lodge a complaint with the data protection supervisory authority
              in your country. You may also have the right to request access to
              and rectification or erasure of your Personal Information or
              restriction of processing of your Personal Information, or to
              object to processing, as well as the right to data
              portability.&nbsp;
            </p>

            <p>
              We respect the privacy rights of all of our users. Upon request
              to&nbsp;
              <a href="mailto:legal@sharepass.com">legal@sharepass.com</a> we
              will provide you with confirmation as to whether we are processing
              your Personal Information, and have the data communicated to you
              within a reasonable time. You have the right to correct, amend or
              delete your Personal Information where it is inaccurate or has
              been processed in violation of this{" "}
              <a href="https://sharepass.com/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>
              .&nbsp;
            </p>

            <p>
              Please allow us a reasonable time to respond to your inquiries and
              requests. We are committed to complying with data protection laws
              to the extent they apply to us, and to assist our users in
              exercising their rights under applicable law.
            </p>

            <p>
              <strong>
                Changes to this{" "}
                <a href="https://sharepass.com/privacy-policy">
                  <strong>Privacy Policy</strong>
                </a>
              </strong>
            </p>

            <p>
              This is our current{" "}
              <a href="https://sharepass.com/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>
              .&nbsp;Our{" "}
              <a href="https://sharepass.com/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>{" "}
              will be updated periodically, with the updated version posted on
              our website<strong> </strong>
              <a href="http://www.sharepass.com">
                <strong>www.sharepass.com</strong>
              </a>
            </p>

            <p>
              By visiting the Website, downloading or updating the Applications,
              and/or otherwise accessing and/or using the Service after we make
              any such changes to this{" "}
              <a href="https://sharepass.com/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>
              , you are deemed to have accepted such changes. Please refer back
              to this{" "}
              <a href="https://sharepass.com/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>{" "}
              on a regular basis.
            </p>

            <p>
              <strong>Contact Details</strong>
            </p>

            <p>
              If you would like further information on our{" "}
              <a href="https://sharepass.com/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>{" "}
              or if you have any concerns over the protection of the information
              you have given to us or that we have collected from others, please
              contact our Privacy Officer at{" "}
              <a href="mailto:legal@sharepass.com">legal@sharepass.com</a>.
            </p>

            <p>
              More information about your rights and our obligations in respect
              to privacy and information on making a privacy complaint are
              available from the Office of the Australian Information
              Commissioner at:
            </p>

            <p>
              Website:&nbsp;
              <a href="http://www.oaic.gov.au">
                www.oaic.gov.au
                <br />
              </a>
              Post: GPO Box 5218 Sydney NSW 2001
              <br />
              Email:&nbsp;
              <a href="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</a>
            </p>

            <p>
              In this{" "}
              <a href="https://sharepass.com/privacy-policy">
                <strong>Privacy Policy</strong>
              </a>
              :
            </p>

            <p>
              The meaning of any general language is not restricted by any
              accompanying example and the words ‘includes’, ‘including’, ‘such
              as’, ‘for example’ or similar words are not words of limitation.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
