import { Helmet } from "react-helmet";
import { useState, useEffect, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import frontendConfigApp from "../../assets/js/config";
import ReactBSAlert from "react-bootstrap-sweetalert";

function RegisterYourInterest() {
    const [submitDisable, setSubmitDisable] = useState(false);
    const [response, setResponse] = useState(<></>);
    const [alert, setAlert] = useState(<></>);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [message, setMessage] = useState('');
    const [enterprise, setEnterprise] = useState(false);

    const [firstNameClass, setFirstNameClass] = useState("form-control");
    const [lastNameClass, setLastNameClass] = useState("form-control");
    const [emailClass, setEmailClass] = useState("form-control");
    const [companyClass, setCompanyClass] = useState("form-control");
    const [messageClass, setMessageClass] = useState("form-control");

    /**
     * reCaptcha code
     */
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {

        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

    }, []);

    const validateEmail = function (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    const handleSubmit = async () => {

        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        setSubmitDisable(true);

        const token = await executeRecaptcha('registerInterest');

        try {

            let error = false;

            if (firstName.trim() == '') {
                setFirstNameClass("form-control error");
                error = true;
            }

            if (lastName.trim() == '') {
                setLastNameClass("form-control error");
                error = true;
            }

            if (company.trim() == '') {
                setCompanyClass("form-control error");
                error = true;
            }

            if (message.trim() == '') {
                setMessageClass("form-control error");
                error = true;
            }

            if (email.trim() == '' || !validateEmail(email)) {
                setEmailClass("form-control error");
                error = true;
            }

            var dataPost = {
                "firstName": firstName,
                "lastName": lastName,
                "companyName": company,
                "email": email,
                "message": message,
                "token": token,
                "enterprise": enterprise
            };


            if (!error) {

                const res = await fetch(frontendConfigApp.api_endpoint + '/registerinterest', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(dataPost),
                });

                const responseJSON = await res.json();

                if (!responseJSON.statusCode && responseJSON.status !== "Success") {
                    setResponse(
                        <div className="alert alert-danger alert-dismissible " role="alert">
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span
                                aria-hidden="true">×</span></button>
                            Sorry, there was an error trying to send your information, please try again.
                        </div>
                    );

                    setSubmitDisable(false);
                } else if (responseJSON.status === "Success") {
                    setFirstName('');
                    setLastName('');
                    setCompany('');
                    setEmail('');
                    setMessage('');

                    setResponse(<></>);

                    setAlert(
                        <ReactBSAlert
                            success
                            style={{ display: "block", marginTop: "100px" }}
                            title="We have registered your interest!"
                            onConfirm={() => {
                                window.location.href = '/';
                            }}
                            confirmBtnBsStyle="primary"
                            confirmBtnText="BACK TO HOME"
                            btnSize=""
                        >
                            Thank you for your interest, we will get in touch soon.<br /> Your request ID is <h3>{responseJSON.details.interestId}</h3>

                        </ReactBSAlert>
                    );

                    setSubmitDisable(false);
                }

                /*return res;

                $.ajax({
                    type: 'POST',
                    url: 'https://hook.integromat.com/wyrai3nyqs42j684x1n6822cee8qw3kt',
                    crossDomain: true,
                    contentType: "application/json",
                    data: JSON.stringify(dataPost),
                    success: function (res) {
                        setSubmitDisable(false);
                        var htmlSucc = $('#successTemplate').html();
                        htmlSucc = htmlSucc.replace(/::message::/g, 'Thank you for your interest, we will get in touch soon.');
                        $('#responseWrapper').html(htmlSucc);
                        $('.register-form').find('input,textarea').val('');
                    },
                    error: function (er) {
                        setSubmitDisable(false);
                        var htmlErr = $('#errorTemplate').html();
                        htmlErr = htmlErr.replace(/::error::/g, 'Sorry, there was an error trying to send your information, please try again.');
                        $('#responseWrapper').html(htmlErr);
                    }
                });*/
            } else {
                setSubmitDisable(false);
            }

        } catch (error) {

            setResponse(
                <div className="alert alert-danger alert-dismissible " role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                    Sorry, there was an error trying to send your information, please try again.
                </div>
            );

            setSubmitDisable(false);

        }
    };

    return (
        <header className="ymt-masthead bg-mountains">
            <Helmet>
                <title>Register your interest | SharePass</title>
                <meta property="og:title" content="SharePass | Register your interest | Don't risk it, SharePass it!" />
            </Helmet>
            {alert}
            <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2196889&vkey=c3740dff03c0f50a19989375840f37a9" />
            <div className="container h-100">

                <div className="row h-100">

                    <div id="response-outer-container" className="col-lg-12">
                        <div className="row mb-2">
                            <div className="col-lg-2"></div>
                            <div id="response-inner-container" className="col-lg-8 register-form">
                                <div className='text-center'>
                                    <h1>Register your interest</h1>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="firstname">First name</label>
                                            <input type="text" className={firstNameClass} id="firstname" placeholder="First name" value={firstName} onInput={e => setFirstName(e.target.value)} onFocus={e => setFirstNameClass("form-control")} />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="lastname">Last name</label>
                                            <input type="text" className={lastNameClass} id="lastname" placeholder="Last name" value={lastName} onInput={e => setLastName(e.target.value)} onFocus={e => setLastNameClass("form-control")} />
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" className={emailClass} id="email" placeholder="Your email" value={email} onInput={e => setEmail(e.target.value)} onFocus={e => setEmailClass("form-control")} />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="company">Company</label>
                                            <input type="text" className={companyClass} id="company" placeholder="Company name" value={company} onInput={e => setCompany(e.target.value)} onFocus={e => setCompanyClass("form-control")} />
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="message">Message</label>
                                            <textarea type="message" className={messageClass} id="message" placeholder="Your message" value={message} onInput={e => setMessage(e.target.value)} onFocus={e => setMessageClass("form-control")}></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            
                                            <div className="fancy-checkbox">
                                                <input onChange={e => setEnterprise(e.target.checked)} type="checkbox" id="enterprise" />
                                                <label htmlFor="enterprise">Interested in SharePass for enterprise?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">{response}</div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group text-center">
                                            <button type="button" onClick={handleSubmit} id="ryiBtn" disabled={submitDisable} className="btn btn-primary"><i className="far fa-paper-plane"></i> Send</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group text-center">
                                            <a href="/" className="btn btn-link"><i className="fas fa-arrow-left"></i> back to Home</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2196889&vkey=c3740dff03c0f50a19989375840f37a9" />
        </header>

    );
}

export default RegisterYourInterest;