import { Fragment, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//import logo from './logo.svg';
import "./App.css";
import "./assets/css/new-age.css?ver=1626812345";
import "./assets/device-mockups/device-mockups.min.css";
import frontendConfigApp from "./assets/js/config";
//import "./assets/vendor/bootstrap/css/bootstrap.min.css";
//import "./assets/vendor/fontawesome-free/css/all.min.css";
import "./assets/vendor/simple-line-icons/css/simple-line-icons.css";
import Layout from "./templates/Layout";
import CookiesPolicy from "./templates/pages/CookiesPolicy";
import ErrorPage from "./templates/pages/ErrorPage";
import FAQ from "./templates/pages/FAQ";
import Home from "./templates/pages/Home";
import PermittedUsePolicy from "./templates/pages/PermittedUsePolicy";
import PrivacyPolicy from "./templates/pages/PrivacyPolicy";
import RegisterYourInterest from "./templates/pages/RegisterYourInterest";
import SubscriptionTerms from "./templates/pages/SubscriptionTerms";
import TermsOfService from "./templates/pages/TermsOfService";

function App() {
  useEffect(() => {
    const scripts = [
      "./assets/js/config.js?ver=1626812345",
      "./assets/vendor/jquery/jquery.min.js",
      //"./assets/js/popper/popper.js",
      "./assets/vendor/bootstrap/js/bootstrap.min.js",
      "./assets/vendor/bootstrap/js/bootstrap.bundle.min.js",
      "./assets/vendor/jquery-easing/jquery.easing.min.js",
      "./assets/js/new-age.min.js",
      "./assets/js/passchecker.js",
      "./assets/js/blockUI.js",
      "./assets/js/sjcl.js",
      "./assets/js/moment-locale.js",
      "./assets/js/phrases.js",
      "./assets/js/custom.js?ver=1626812345",
      "./assets/js/global.js?ver=1626812345",
    ];

    let scriptsObj = [];

    for (let i = 0; i < scripts.length; i++) {
      const script = document.createElement("script");
      script.src = scripts[i];
      script.async = false;
      document.body.appendChild(script);
      scriptsObj.push(script);
    }

    return () => {
      for (let i = 0; i < scriptsObj.length; i++) {
        document.body.removeChild(scriptsObj[i]);
      }
    };
  }, []);

  return (
    <Router>
      <Fragment>
        <GoogleReCaptchaProvider
          reCaptchaKey={frontendConfigApp.recaptcha_site_keys}
        >
          <Routes>
            <Route
              path="/terms-of-service"
              element={<Layout logoHome={true} page={<TermsOfService />} />}
            />
            <Route
              path="/permitted-use-policy"
              element={<Layout logoHome={true} page={<PermittedUsePolicy />} />}
            />
            <Route
              path="/privacy-policy"
              element={<Layout logoHome={true} page={<PrivacyPolicy />} />}
            />
            <Route
              path="/subscription-terms"
              element={<Layout logoHome={true} page={<SubscriptionTerms />} />}
            />
            <Route
              path="/cookies-policy"
              element={<Layout logoHome={true} page={<CookiesPolicy />} />}
            />
            <Route
              path="/faq"
              element={<Layout logoHome={true} page={<FAQ />} />}
            />
            <Route
              path="/register-your-interest"
              element={
                <Layout logoHome={true} page={<RegisterYourInterest />} />
              }
            />
            <Route
              path="/error"
              element={<Layout logoHome={true} page={<ErrorPage />} />}
            />
            <Route
              path="/"
              element={
                <Layout logoHome={false} header={true} page={<Home />} />
              }
            />
            <Route
              path="*"
              element={<Layout logoHome={true} page={<ErrorPage />} />}
            />
          </Routes>
        </GoogleReCaptchaProvider>
      </Fragment>
    </Router>
  );
}

export default App;
