import Navigation from "./blocks/Navigation";
import Header from "./blocks/Header";
import Footer from "./blocks/Footer";
import FloatSocial from "./blocks/FloatSocial";

function Layout({ page, header, logoHome }) {
    let headerFragment;
    let extraClass;
    let isHome = header;
    if(isHome){
        //Is home, header is only present in home page
        headerFragment = <Header />;
    }else{
        extraClass = ' force-nav-bg';
    }

    return (
        <>
            <Navigation extraClass={extraClass} logoHome={logoHome} />
            {headerFragment}

            {page}

            <FloatSocial />

            <Footer isHome={isHome}/>
        </>
    );
}

export default Layout;