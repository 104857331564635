import { Helmet } from "react-helmet";
function FAQ() {

    return (

        <section className="page-container">
            <Helmet>
                <title>FAQ | SharePass</title>
                <meta property="og:title" content="SharePass | FAQ | Don't risk it, SharePass it!" />
            </Helmet>
            <div className="container">
                <div className="row">
                    <div className="col-md-10 mx-auto">
                        <h1 className="text-center section-heading"> FAQ </h1>
                        <h2>Q: What is SharePass?</h2>

                        <p>SharePass resolves the problem of sharing sensitive data (passwords, tokens,
                            private keys, PINs, secret messages, QRs, etc) with 3rd party entites.</p>

                        <h2>Q: Why should I use SharePass?</h2>

                        <p>Sender and receiver do not need to install any 3rd party application. Additionally,
                            secret messages are sent encrypted and can be automatically destroyed once it is
                            delivered to the recipient.</p>

                        <h2>Q: Is SharePass secure?</h2>

                        <p>Yes. The secret messages are saved encrypted (256 bits AES) and only a person who has the link (and special
                            permissions if requested by sender) will be able to see it. Registered and enterprise users will have the ability to choose which encryption algorithm to use.</p>

                        <h2>Q: Would SharePass know my secrets?</h2>

                        <p>No. That is one of the key strengths about SharePass. We value our customer's privacy. SharePass will
                            only save the encrypted secret message and won't save the key needed to decrypt the messages,
                            instead, the key will be delivered included with the link together with the secret message id to
                            the recipient.
                        </p>

                        <h2>Q: What happens if I open the link by mistake or lost it?</h2>

                        <p>If you open the link by mistake or lost it, you will need to generate a new one.</p>

                        <h2>Q: Is it free?</h2>

                        <p>We offer a limited version for free, this version will allow sharing passwords and messages only. With the free version you can share upto 5
                            secrets a day. Each secret is limited to 1000 bytes.</p>

                        <h2>Q: Is it possible to integrate SharePass with other applications?</h2>

                        <p>Totally. SharePass is built with an open API that you can use to integrate it to your platform or
                            application. SharePass will also allow some plans for registered users to use webhooks and to get notifications when a secret has been viewed.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FAQ;