import Marquee from "react-fast-marquee";

function Footer({isHome}) {
  const year = new Date().getFullYear();

  return (
    <>
    {isHome && (
      <div className="white-background-frame pt-5 pb-5">
        <div className="container text-center">
          <div className="row mb-2 mt-3">
            <div className="col-md-12 badges">
              <h2 className="footer-title mb-4">Partners</h2>

              <div className="d-inline-block">
                <a
                  className="review-icon"
                  href="https://www.nvidia.com/en-us/startups/"
                  target="blank"
                >
                  <img
                    border="0"
                    className="nvidia-badge"
                    src="/assets/img/nvidia-inception-transparent.png"
                  />
                </a>
              </div>

              <div className="d-inline-block">
                <a
                  className="review-icon yubikey-badge"
                  href="https://www.yubico.com/works-with-yubikey/catalog/SharePass/"
                  target="blank"
                >
                  <img
                    border="0"
                    className="nvidia-badge"
                    src="/assets/img/WWYK-Program-logo-green@3x.png"
                  />
                </a>
              </div>

              <hr className="footer-separator" />

              <h2 className="footer-title mb-4">Awards</h2>

              <Marquee gradient={false} pauseOnHover={false}>
                <div className="d-inline-block">
                  <a
                    className="review-icon"
                    href="https://globeeawards.com/cyber-security/winners/"
                    target="blank"
                  >
                    <img
                      border="0"
                      className="nvidia-badge"
                      src="/assets/img/cybersecuritybronze.png"
                    />
                  </a>
                </div>

                <div className="d-inline-block">
                  <a
                    className="review-icon"
                    href="https://cybersecurity-excellence-awards.com/candidates/sharepass-digital-footprint-management/"
                    target="blank"
                  >
                    <img
                      border="0"
                      className="nvidia-badge"
                      src="/assets/img/csea.png"
                    />
                  </a>
                </div>

                <div className="d-inline-block">
                  <a
                    className="review-icon"
                    href="https://www.businessnewsaustralia.com/young-entrepreneur-awards.html"
                    target="blank"
                  >
                    <img
                      border="0"
                      className="nvidia-badge"
                      src="/assets/img/YE2022.png"
                    />
                  </a>
                </div>

                <div className="d-inline-block">
                  <div
                    className="sf-root review-icon"
                    data-id="3518941"
                    data-badge="top-performer-summer-white"
                    data-variant-id="sf"
                    data-metadata="achievement=top-performer-seasonal-2022-summer"
                    style={{ width: "150px" }}
                  >
                    <a
                      href="https://sourceforge.net/software/product/SharePass/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      SharePass Reviews
                    </a>
                  </div>
                </div>

                <div className="d-inline-block">
                  <div
                    className="review-icon sf-root"
                    data-id="3518941"
                    data-badge="top-performer-fall-white"
                    data-variant-id="sf"
                    data-metadata="achievement=top-performer-seasonal-2022-fall"
                    style={{ width: "150px" }}
                  >
                    <a
                      href="https://sourceforge.net/software/product/SharePass/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      SharePass Reviews
                    </a>
                  </div>
                </div>

                <div className="d-inline-block">
                  <div
                    className="review-icon sf-root"
                    data-id="3518941"
                    data-badge="top-performer-summer-white"
                    data-variant-id="sd"
                    data-metadata="achievement=top-performer-seasonal-2022-summer"
                    style={{ width: "140px" }}
                  >
                    <a
                      href="https://slashdot.org/software/p/SharePass/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      SharePass Reviews
                    </a>
                  </div>
                </div>

                <div className="d-inline-block">
                  <div
                    className="review-icon sf-root"
                    data-id="3518941"
                    data-badge="top-performer-fall-white"
                    data-variant-id="sd"
                    data-metadata="achievement=top-performer-seasonal-2022-fall"
                    style={{ width: "140px" }}
                  >
                    <a
                      href="https://slashdot.org/software/p/SharePass/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      SharePass Reviews
                    </a>
                  </div>
                </div>

                <div className="d-inline-block">
                  <a 
                    className="review-icon sf-root"
                    href="https://www.capterra.com/p/234300/SharePass/" 
                    target="_blank" 
                    rel="noreferrer"
                  > 
                    <img border="0" className="nvidia-badge" src="https://capterra.s3.amazonaws.com/assets/images/gdm-badges/CA_Badge_BestEaseofUse_2022_FullColor-Positive.png" /> 
                  </a>
                </div>
              

                <div className="d-inline-block">
                  <a 
                    className="review-icon sf-root"
                    href="https://www.capterra.com/p/234300/SharePass/" 
                    target="_blank" 
                    rel="noreferrer"
                  >
                    <img className="nvidia-badge" border="0" src="https://capterra.s3.amazonaws.com/assets/images/gdm-badges/CA_Badge_BestValue_2022_FullColor-Positive.png" /> 
                  </a>
                </div>
              </Marquee>

              <hr className="footer-separator" />
              <h2 className="footer-title mb-4">Review us</h2>

              <div>
                <a
                  className="review-icon"
                  href="https://www.capterra.com/reviews/234300/SharePass?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                  target="blank"
                >
                  {" "}
                  <img
                    border="0"
                    height="80"
                    src="https://assets.capterra.com/badge/c7a60945dfcac38a2dd2281d4cc73c05.svg?v=2196889&p=234300"
                  />
                </a>
                <a
                  className="review-icon"
                  href="https://www.g2.com/products/sharepass-sharepass/reviews"
                  target="blank"
                >
                  {" "}
                  <img border="0" height="80" src="/assets/img/g2.png" />{" "}
                </a>

                <a
                  className="review-icon"
                  href="https://www.getapp.com/security-software/a/sharepass/reviews/"
                  target="blank"
                >
                  <img
                    border="0"
                    height="80"
                    src="https://www.getapp.com/ext/reviews_widget/v1/dark/sharepass-application"
                  />
                </a>
                {false && <a
                  className="review-icon"
                  href="https://www.softwareadvice.com.au/software/319320/sharepass"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    border="0"
                    height="80"
                    src="https://badges.softwareadvice.com/reviews/d0f1c003-59f4-431b-91fb-ae7c08a99ac1"
                  />{" "}
                </a>}
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    
    <footer className={"dark-footer"}>
      <div className="container">
        
        
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <p>
              © {year} SharePass Pty Ltd (ACN 647 015 601) (
              <strong>SharePass</strong>). SharePass is a dedicated solution for
              confidential data sharing. Our mission is to make your online
              presence more secure and confidential.
              <br />
              All rights reserved.{" "}
            </p>
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="/terms-of-service">Terms of Service</a>
              </li>

              <li className="list-inline-item">
                <a href="/subscription-terms">Subscription Terms</a>
              </li>

              <li className="list-inline-item">
                <a href="/permitted-use-policy">Permitted Use Policy</a>
              </li>

              <li className="list-inline-item">
                <a href="/privacy-policy">Privacy policy</a>
              </li>

              <li className="list-inline-item">
                <a href="/cookies-policy">Cookies Policy</a>
              </li>

              <li className="list-inline-item">
                <a href="/faq">FAQ</a>
              </li>
            </ul>
          </div>
          
          <div className="col-md-2"></div>
        </div>
      </div>
      {true && <div className="text-center">
        <ul className="list-inline">
          <li className="list-inline-item">
            <a target="_blank" href="https://developers.sharepass.com/">
              <i className="fa fa-code"></i> Developers
            </a>
          </li>
        </ul>
      </div>}
    </footer>
    </>
    
  );
}

export default Footer;
