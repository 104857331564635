import { useNavigate } from 'react-router-dom';
import frontendConfigApp from '../../assets/js/config';

function Navigation({extraClass, logoHome}) {

    const navigate = useNavigate();

    let classes = `navbar navbar-expand-lg navbar-light fixed-top ${extraClass}`;

    let logoHref = "#page-top";
    let explainer = "#sharepass-explainer";
    let about = "#sharepass-about";
    let features = "#sharepass-features";

    if(logoHome){
        logoHref = "/";
        explainer = "/#sharepass-explainer";
        about = "/#sharepass-about";
        features = "/#sharepass-features";
    }
    
    //data-toggle="modal" data-target="#registerInterestModal"

    return (
        <nav id="mainNav" className={classes}>
            <div className="container">
                <a className="navbar-brand js-scroll-trigger" href={logoHref} >
                    <img className="logo" src="/assets/img/SP_logo.png" />
                </a>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                    data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                    aria-label="Toggle navigation">
                    Menu
                    <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ml-auto">

                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href={explainer}>
                                Explainer
                            </a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href={about}>
                                About
                            </a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger"  href={features}>
                                Features
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" target="blank" href="https://blog.sharepass.com">
                                Blog
                            </a>
                        </li>



                        <li className="nav-item">
                            <button className="nav-link btn btn-primary reg-nav-button" type="button" onClick={() => navigate('/register-your-interest')}  
                            data-toggle="collapse"
                            data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" >
                                Register your interest
                            </button>
                        </li>

                        <li className="nav-item extra-margin-menu d-none">
                            <a className="nav-link btn btn-primary singup-nav-button" href="/">
                                Sign up
                            </a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link btn btn-primary signin-nav-button" href={frontendConfigApp.app_url}>
                                Sign in
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;